@import "/src/scss/var";

.product-main-container {
  height: 100%;
  border-radius: 8px;
  background-color: $gray;
  // background-image: url("../../assets/images/main-product-img.png");
  // background-size: cover;
  // background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  // background: linear-gradient(
  //   181deg,
  //   rgba(25, 57, 86, 0) 0%,
  //   #122f4a 100%
  // ),
  // lightgray -38.101px -58px / 134.219% 135.973% no-repeat;;
  .product-content {
    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: white;
    }

    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $yellow;
    }
  }
}
