@import "./scss/styles";

.app-container {
  height: calc(100vh - 100px);
  &::-webkit-scrollbar {
    display: none;
  }

  background-color: $backgroundBlue;
  @media (min-width: $md_min) {
    margin: 0;
    max-height: 830px;
    height: 800px;
    // height: 100%;
    padding: 0 12rem;
    padding: 72px 12rem 72px 12rem;

  }
}
