@import "./var";
body {
  background-color: $backgroundBlue;
  font-family: Roboto;
}

input {
  border: 1px solid $inputBorder2;
  border-radius: 8px;
  background-color: $white;
  font-size: 11.5026px;
  font-weight: 400;
  line-height: 16px;
  padding: 10px 17px;

  &::placeholder {
    font-size: 11.5026px;
    color: $inputPlaceholder;
  }
}

.btn-navigate-wrapper {
  border: solid 1px $blue;
  padding: 5px 34px;
  border-radius: 11px;
  .btn-navigate {
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: $blue;
  }
}

.btn {
  border-radius: 32px;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  padding: 6px 18px;
  &.primary {
    background-color: $white;
    border: 1px solid $blue;
    color: $neutral;
  }

  &.secondary {
    color: $white;
    background-color: $blue;
  }

  &.plus {
    color: $white;
    background-color: $btnGray;
    &::before {
      margin-right: 5px;
      content: url("../assets/icons/plus.svg");
    }
  }

  &.border-gray {
    border: solid 1px $gray3;
  }
}

.btn-submit {
  background-color: $gray;
  color: $black;
  font-weight: 700;
  font-size: 19.6549px;
  line-height: 24px;
  padding: 10px;
}

.per-page {
  width: 100%;
  // box-shadow: -3.8342px 4.79275px 26.8394px rgba(0, 0, 0, 0.2),
  //   inset -13.4197px -0.958549px 26.8394px -0.958549px rgba(149, 149, 149, 0.25);

  @media (min-width: $md_min) {
    // padding: 71px 0;
    width: 555px;
    max-width: 555px;
  }
}

.description {
  font-weight: 500;
  font-size: 11.5026px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.37);
}

.label-txt {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: $selectOptionTxt;
}

.page-container-flip {
  @media (min-width: $md_min) {
  }

  .flip {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
    left: 0px;
    transition: all 2s ease-in;
    transform: rotateY(0deg);
    transform-style: preserve-3d;
    transform-origin: left;

    animation-duration: 2s;

    &.animation-right-to-left {
      animation-name: flipRightToLeft;
      border-radius: 0 8px 8px 0;
    }

    &.animation-left-to-right {
      animation-name: flipLeftToRight;
      border-radius: 0 8px 8px 0;
    }

    transform: rotateY(-120deg);
  }

  .page-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 8px;
    top: 0;
    background-color: transparent;
    left: 0px;
    transition: all .8s linear;
    transform: rotateY(0deg);
    transform-style: preserve-3d;
    transform-origin: left;
    z-index: -1;
  }

  position: relative;
  transform: translate(0%, 0%);
  perspective: 2000px;
}

@keyframes flipRightToLeft {
  from {
    background-color: $white;
    transform: rotateY(0deg);
    opacity: 2;
    z-index: 9;
  }
  to {
    transform: rotateY(-180deg);
    background-color: $white;
    opacity: 2;
    z-index: 9;
  }
}

@keyframes flipLeftToRight {
  from {
    background-color: $white;
    transform: rotateY(-180deg);
    opacity: 2;
  }
  to {
    transform: rotateY(0deg);
    background-color: $white;
    opacity: 2;
  }
}

.bg-white {
  background-color: $white;
}

.bg-gradient {
  background-color: #fefdfb;
  background: linear-gradient(225deg, #fff6e2, #fefdfb);
}

.page-left {
  border-radius: 8px 0 0 8px;

  &.shadow-left {
    z-index: 1;
    box-shadow: -13.419689178466797px -0.9585492014884949px 26.839378356933594px -0.9585492014884949px
        rgba(149, 149, 149, 0.25) inset,
      -3.8341968059539795px 4.792746067047119px 26.839378356933594px 0px #141c27;
  }
}

.page-right {
  border-radius: 0 8px 8px 0;

  &.shadow-right {

    box-shadow: 0.9585492014884949px 0.9585492014884949px 5.75129508972168px 0px
        rgba(0, 0, 0, 0.25) inset,
      0.9585492014884949px 3.8341968059539795px 8.62694263458252px 0px
        rgba(0, 0, 0, 0.15);
  }
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 160px;
  height: 1.2em;
  white-space: nowrap;
}

.cut-name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media (min-width: $md_min) {
    -webkit-line-clamp: 2;
  }
}

.tag {
  font-weight: 700;
  font-size: 12px;
  color: $blue2;
  border-radius: 50px;
  border: solid 1px $gray;
  padding: 0.5rem 1.1rem;
  &.active {
    background-color: $blue4;
    border: none;
  }
}

@mixin scrollBar() {
  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $--neutral-light;
    border-radius: 10px;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.3);
  }
}

.scrollbar-custom {
  @include scrollBar();
}

.loader-wrapper {
  z-index: 99;
  background: linear-gradient(
    90deg,
    rgba(40, 49, 62, 0.28) 100%,
    rgba(40, 49, 62, 0) 100%
  );
  // opacity: 0.3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid $blue;
  // border-bottom: 6px solid $blue;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  @media (min-width: $md_min) {
    margin-left: 9rem;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn-show-more {
  cursor: pointer;
  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $blue2;
  }

  img {
    &.show-less {
      transform: rotate(180deg);
    }
  }
}

.forbidden {
  color: $textColor2;
  h1 {
    font-size: 5rem;
    margin: 0;
    color: $textColor1;
  }
}
