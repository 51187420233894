@import "/src/scss/var";
.nav-bar {
  background-color: $white;

  .btn-history {
    @media (min-width: $md_min) {
      border-top: solid 1px $gray3;
      margin: 0 .5rem;
    }

  }
  @media (min-width: $md_min) {
    border-radius: 16px;
    height: 602px;
  }
  .group-category {
    .image {
      border-radius: 100%;
      width: 40px;
      height: 40px;
      @media (min-width: $md_min) {
        background-color: $gray;
        &.active {
          background-color: $orange;
        }
      }
    }

    .category-name {
      color: $gray2;
      font-size: 12px;
      line-height: 20px;
      font-weight: 500;
      @media (min-width: $md_min) {
        font-size: 14px;
      }

      &.active {
        color: $yellow
      }
    }
  }

  .nav-navigate {
    .image {
      background-color: $gray;
      border-radius: 100%;
      width: 40px;
      height: 40px;
    }

    .navigate-name {
      color: $neutral;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
    }
  }
}
