@import "/src/scss/var";

.app-container {
  .block-end {
    right: -90%;
    @media (min-width: $md_min) {
      right: -70%;
    }

    @media (min-width: $lg_min) {
      right: -40%;
    }

    @media (min-width: $xl_min) {
      right: -20%;
    }

  }
  .nav-container {
    z-index: 2;
    @media (min-width: $md_min) {
      height: 100%;
      width: 145px;
      background: linear-gradient(
        90deg,
        rgba(40, 49, 62, 0.28) 0%,
        rgba(40, 49, 62, 0) 100%
      );
    }
  }
}
