@import "/src/scss/var";

.slider-container {
  width: 100%;
  position: relative;

  @media (min-width: $md_min) {
    // padding: 10px 0;
  }

  .e-catalog {
    color: $blue;
  }

  .e-desc {
    color: $neutral;
  }

  .btn-action {
    background-color: $gray !important;
    border-radius: 100%;
    background: 100%;
    // position: absolute;
    top: 50%;
    bottom: 50%;
    z-index: 9;
    width: 33px;
    height: 33px;
    display: none;
    @media (min-width: $md_min) {
      display: block;
    }
    &.btn-prev {
      left: 1.3rem;
      padding: 0 10px;
    }
    &.btn-next {
      right: 1.3rem;
      padding: 0 12px;
    }
  }
}
