@import "/src/scss/var";

.product-container {
  // width: 155px;

  .product-image {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(226, 226, 226, 1) 99%
    );
    border-radius: 8px;
    // background-image: url('https://cdn.pnj.io/images/detailed/32/sbxm00k000180-bong-tai-nam-bac-dinh-da-pnjsilver-my-man.png');
    background-size: contain;
    background-repeat: no-repeat;
    // min-height: 105px;
    height: 135px;
    @media (min-width: $md_min) {
      height: 136px;
    }
  }
  .product-content {
    p, span {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: $textColor4;
    }

    span {
      @extend p;
      color: $yellow;
    }
  }
}
