@import "/src/scss/var";
@import "/src/scss/var";

.diamond-collection {
  min-height: 456px;
  // background-color: $rectangle1;
  @media (min-width: $md_min) {
    min-height: 373px;
  }

  .banner {
    // background-image: url('../../assets//images/pnj-banner.png');
    background-repeat: no-repeat;
    background-size: contain;
    height: 450px;
  }
}
