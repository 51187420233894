@import "/src/scss/var";

.total {
    font-size: 16px;
    .total-label {
        color: $gray5;
    }

    .total-price {
        color: $blue2
    }
}