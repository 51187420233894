@import "/src/scss/var";

.info-container {
  .block-id {
    color: $neutral2;
    .order-id {
      color: $blue2;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
    }
    label {
      font-weight: 500;
      font-size: 17px;
      line-height: 25px;
    }

    .status-tag {
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      // background-color: $gray;
      padding: 6px 18px;
      border-radius: 50px;

      &.success {
        background-color: $--transparent-success-8;
        color: $--success-main;
      }

      &.created {
        background-color: $--transparent-black-08;
        color: $--grey-600;
      }
    }
  }

  .block-user {
    label {
      font-size: 12px;
      color: $--grey-600;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: $neutral;
    }
  }
}
