@import "/src/scss/var";
.page-header-container {
  background-color: $white;
  .title-container {
    h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: $blue2;
    }
  }

  .page-content {
    height: calc(100% - 70px);
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .wrapper {
    background-color: $white;
    animation-name: showPage;
    transition: all 2s ease-in;
    animation-duration: 3s;
    opacity: 0;
    overflow: hidden;
    inset: 0;
    pointer-events: none;
    @media (min-width: $md_min) {
      transition: all 2s ease-in;
      animation-duration: 3.5s;
      border-radius: 8px;
    }
  }
}

@keyframes showPage {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
