@import "/src/scss/var";

.product-content-container {
  // height: calc(100vh - 100px);
  .product-des {
    .product-title {
      font-size: 16px;
      color: $neutral;
      font-weight: 600;
      line-height: 24px;
    }
    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: $neutral;
    }

    span {
      font-weight: 500;
      font-size: 13.135px;
      line-height: 20px;
      color: $yellow;
    }

    .load-more {
      color: $blue3;
    }

    .bag {
      padding: 5px 5px;
      background-color: $blue2;
      border-radius: 7px;
      width: 36px;
      height: 32px;
    }

    .tag {
      font-weight: 700;
      font-size: 13px;
      color: $blue2;
      border-radius: 50px;
      border: solid 1px $gray;
      padding: 0.5rem 1.5rem;
      &.active {
        background-color: $blue4;
        border: none;
      }
    }

    .detail-content {
      // max-height: 105px;
      // overflow-y: scroll;
      span {
        color: $neutral;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
      }

    }
  }
}
