@import "/src/scss/var";

.history-container {
  .product-wrapper {
    max-height: 150px;
  }
  .total-price {
    background-color: var(--transparent-info-8, rgba(0, 152, 217, 0.08));
    padding: 10px 12px;
    border-radius: 6px;
    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: $--grey-600;
    }

    label {
      @extend span;
    }
  }
}
