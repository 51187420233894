@import "/src/scss/var";

.video-wrapper {
  width: 251px;
  height: 270px;
  margin: auto;
  .image-content {
    margin: auto;
    height: 100%;
  }
}
.images-slider {
  width: 260px;
  margin: auto;
  display: flex !important;
  justify-content: center;
  gap: 8px;
  .slick-list {
    height: 35px;
    .slick-track {
      height: 100%;
    }

    .slick-slide {
      // margin: 0 2px;
    }
  }
  .list-image-wrapper {
    &:focus-visible {
      outline: none;
      background-color: transparent;
    }
    .img-item {
      width: 35px;
      height: 35px;
      border-radius: 6px;
      background-color: $gray6;
       margin: 0 2px;

      &.active {
        border: solid 1px $gray3;
      }
    }

    .play-wrapper {
      width: 35px;
      height: 35px;
      border-radius: 6px;
      background: #b7b6b6;
      opacity: 0.8;
      left: 2px;
    }

    .play-icon {
      width: 35px;
      height: 35px;
      left: 2px;
      img {
        width: 26px;
        height: 26px;
      }
    }
  }
}
