@import "/src/scss/var";

.scan-code-container {
  .qr-code {
    width: 196px;
    height: 176px;
    // background-color: $gray;
    background-image: url("https://cdn.britannica.com/17/155017-050-9AC96FC8/Example-QR-code.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    margin: auto;
  }
  .store-info {
    h3 {
      font-weight: 700;
      font-size: 19.6549px;
      line-height: 24px;
      text-transform: uppercase;
      color: $black;
    }
  }
}
