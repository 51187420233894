@import "/src/scss/var";

.page-right-wrapper {
  height: calc(100% - 25px) !important
}

.group-info {
  label {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: $selectOptionTxt;
  }

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: $--grey-700;
  }
}

.info-shipping {
  .qr-desc {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: $--neutral-light;
  }

  .qr-code {
    width: 100px;
    height: 100px;
    // background-color: $gray;
    // background-image: url("https://cdn.britannica.com/17/155017-050-9AC96FC8/Example-QR-code.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    margin: auto;
    @media (min-width: $md_min) {
        width: 130px;
        height: 130px;
    }
  }

  .summary {
    padding: 15px 13px 15px 23px;
    border-radius: 6px;
    background-color: var(--transparent-info-8, rgba(0, 152, 217, 0.08));
    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      color: $selectOptionTxt;
    }

    .total-price {
      p {
        font-size: 14px;

        &.price {
          font-weight: 600;
          color: $selectOptionTxt;
        }

        &.label {
          font-weight: 400;
          color: $gray3;
        }
      }
    }
  }
}
