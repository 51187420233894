$black: #000000;
$gray4: #E3E3E3;
$white: #FFFFFF;
$yellow: #FDA83E;
$orange: #FDE5C8;
$textColor1: #5C6B74;
$textColor2: #A0A1A1;
$textColor3: #515151;
$textColor4: #003768;
$blue: #196FAA;
$blue2: #006C9C;
$blue3: #1A6FAA;
$blue4: #c2e6f6;
$--transparent-success-8: #eff9f5;
$--success-main: #36B37E;
$backgroundBlue: #28313E;
$--transparent-black-08: #ebebeb;
$--grey-600: #637381;

$gray: #D9D9D9;
$gray2: rgba(145, 158, 171, 1);
$gray3: #919EAB;
$gray5: #C4CDD5;
$gray6: #F4F4F4;
$neutral: #455560;
$neutral2: #1A3353;
$typographyText: rgba(0, 0, 0, 0.37);
$btnGray: #707070;
$rectangle1: #EAEAEA;
$bgColor: #A3A2A3;
$borderColor: #AAAAAA;
$inputBorder: rgba(0, 0, 0, 0.2);
$inputBorder2: rgba(145, 158, 171, 0.32);
$inputPlaceholder: #D0D4D7;
$selectOptionTxt: #637381;
$--grey-700: #454F5B;
$--neutral-light: #72849A;

$xs_min: 320px;
$sm_min: 640px;
$md_min: 768px;
$lg_min: 1024px;
$xl_min: 1280px;
$xxl_min: 1536px;

$font-path: '~/src/assets/fonts/';
