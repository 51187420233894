@import "/src/scss/var";

.select-box-container {
  .select-val {
    padding: 7px 14px;
    border: solid 1px $inputBorder2;
    border-radius: 8px;
  }

  .option {
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: $selectOptionTxt;
  }

  .no-option {
    @extend .option;
    border: solid 1px $inputBorder2;
    border-radius: 8px;
    padding: 0.4rem 1rem;
    margin-top: .4rem;
  }

  .option-list {
    position: absolute;
    z-index: 2;
    top: 3rem;
    background-color: $white;
    border: solid 1px $inputBorder2;
    padding: 0 1rem;
    border-radius: 8px;
    max-height: 300px;
    overflow-y: scroll;


  }

  select {
    border: 1px solid $inputBorder;
    border-radius: 8px;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    padding: 16px 14px;

    option {

    }
  }
}
