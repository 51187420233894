@import "/src/scss/var";

.group-input-text {
  label {
    position: absolute;
    top: -9px;
    left: 14px;
    font-size: 12px;
    background: $white;
    color: $gray3;
    padding: 0 2px;
  }
  input {
    border: 1px solid $inputBorder2;
    border-radius: 8px;
    background-color: $white;
    font-size: 11.5026px;
    font-weight: 400;
    line-height: 16px;
    padding: 10px 17px;
    
    &::placeholder {
        font-size: 11.5026px;
        color: $inputPlaceholder
    }
  }
}
