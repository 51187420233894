@import "/src/scss/var";

.item-container {
  .image-wrapper {
    width: 60px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(226, 226, 226, 1) 99%
    );
    border-radius: 8px;
  }
  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $neutral;
  }
  .price {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $yellow;
  }

  .quantity {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: $--neutral-light;
  }
}
